<template>
  <v-dialog v-model="fddialog" max-width="1600px" @click:outside="onClose">
    <v-card flat>
      <v-app-bar absolute flat color="white">
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title
            >Fixture Design Details: {{ partNumber }} -
            {{ projectName }}</v-toolbar-title
          >
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="close-button">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-app-bar>
      <v-sheet id="scroll-body" class="scroller">
        <v-container fluid class="mt-13">
          <div v-if="designFilePackages.length > 0">
            <v-toolbar dense flat>
              <v-row>
                <v-col cols="12" md="8" class="d-flex align-center subtitle-1">
                  <span>
                    Fixture Design State:
                    {{ fixtureStateName }}
                    - {{ stateDescription }}
                  </span>
                </v-col>
              </v-row>
            </v-toolbar>
          </div>
          <div class="my-5">
            <v-sheet
              v-for="(designFilePackage, index) in designFilePackages"
              :key="index"
              outlined
              class="mb-5 pa-1"
              elevation="6"
            >
              <FixtureDesignBlock
                ref="designBlock"
                :isAdmin="isAdmin"
                :designFilePackage="designFilePackage"
                :activeId="activeId"
                :isDFPView="true"
                :isModalRendered="true"
              />
            </v-sheet>
          </div>
          <v-row dense class="my-10">
            <v-col cols="12" md="4">
              <v-btn
                v-if="isAdmin"
                x-large
                block
                color="warning"
                @click="onDownloadJson"
                data-test="copy-json-button"
                >Download Fixture Design JSON file<v-icon right>
                  mdi-cloud-download
                </v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" md="4" offset-md="4">
              <v-btn
                v-if="isAdmin"
                x-large
                block
                :disabled="!bomUrl"
                color="warning"
                target="_blank"
                :href="bomUrl"
                data-test="bom-button"
                >Go to Full BOM View
                <v-icon small class="ml-1">
                  mdi-open-in-new
                </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-divider />
          <v-row dense class="mt-10 mb-5">
            <v-col cols="12" md="4">
              <v-btn
                v-if="isAdmin"
                x-large
                block
                color="warning"
                @click="
                  gotoFulfillment({
                    fdId: fixtureDesign.project,
                    newTab: false,
                  })
                "
                @contextmenu="
                  activateMenu({
                    nextRoute: {
                      fdId: fixtureDesign.project,
                      newTab: true,
                    },
                    nextAction: 'gotoFulfillment',
                    event: $event,
                  })
                "
                data-test="fulfillment-button"
                >Go to Fixture Design Fulfillment View</v-btn
              >
            </v-col>
            <v-col cols="12" md="4" offset-md="4">
              <v-btn
                x-large
                block
                color="secondary"
                @click="gotoQuoteModal(fixtureDesign.quote)"
                data-test="quote-modal-button"
                >Display Quote Details</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <!-- </v-card-text> -->
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
import { fileDownloader } from "@/helpers/basehelper.js";
export default {
  name: "FixtureDesignModal",
  props: {
    fddialog: {
      type: Boolean,
      default: false,
    },
    fixtureDesign: {
      type: Object,
      default: null,
    },
    selectedProject: {
      type: Object,
      default: null,
    },
    fdState: {
      type: Array,
      default: () => [],
    },
    fdStateLongList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    FixtureDesignBlock: () =>
      import("@/modules/design-packages/components/FixtureDesignBlock.vue"),
  },
  computed: {
    projectName() {
      return this.selectedProject.project_name;
    },
    bomUrl() {
      return Object.values(this.selectedProject.fixture_design_details)[0]
        ?.bom_url;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : "";
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    stateDescription() {
      const result = this.fdStateLongList.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : null;
    },
    designFilePackages() {
      return this.fixtureDesign
        ? [...this.fixtureDesign.design_file_packages].sort((a, b) => {
            if (a.active === b.active) {
              return b.version - a.version;
            }
            return a.active < b.active ? 1 : -1;
          })
        : [];
    },
    activeId() {
      const record = this.fixtureDesign?.design_file_packages?.find(
        (element) => element.active
      );
      if (record) {
        return record.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    fileDownloader,
    onClose() {
      this.$emit("closeFDDetailsModal");
    },
    onDownloadJson() {
      const data = JSON.stringify(this.fixtureDesign, null, 2);
      const jsonUrl = window.URL.createObjectURL(new Blob([data]));
      const actionPayload = {
        url: jsonUrl,
        file: `${this.partNumber} - ${this.projectName}.json`,
      };
      this.fileDownloader(actionPayload);
    },
    gotoFulfillment(payload) {
      this.$emit("onGoToFulfillment", payload);
    },
    gotoQuoteModal(payload) {
      this.onClose();
      this.$emit("gotoQuoteModal", payload);
    },
    activateMenu(payload) {
      this.$emit("onActivateMenu", payload);
    },
  },
};
</script>
<style scoped>
.scroller {
  height: auto;
  overflow-y: auto;
}
</style>
